import { Fragment, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { isMobile, isMobileOnly } from 'react-device-detect'
import { Dialog, Transition } from '@headlessui/react'
import { CLOSE_PANEL } from '@components/utils/textVariables'
import { Cross } from '@components/icons'
import { LoadingDots, Skeleton, useUI } from '@components/ui'
import { matchStrings } from '@framework/utils/parse-util'
import { API_STATUS } from '@components/utils/constants'
import { useMoEngageEvent } from 'hooks/useMoengageEvent'
import Button from '@components/ui/AddButton'
import SizeRadioSection from './SizeRadioSection'
import { variantProductFilter } from './helper'
import { IDeviceInfo } from '@components/ui/context'

interface ISizeChangePopup {
  show: boolean
  loaderState: boolean
  handleCloseSizeModal: (val: boolean) => void
  setSelectedProduct: any
  setProductSizes: any
  setVariantProducts: any
  buttonConfig: any
  productSizes: any
  deviceInfo: IDeviceInfo
  SizeChart: any
  currentVariantData: any
  isSizeChartEnable: string
  variantProducts: any
  selectedProduct: any
  setCurrentVariantData: any
  headerCls?: string
  attributeCls?: string
}

const SizeChangePopup = (props: ISizeChangePopup) => {
  const {
    show,
    loaderState,
    handleCloseSizeModal,
    setSelectedProduct,
    setProductSizes,
    setVariantProducts,
    buttonConfig,
    productSizes,
    deviceInfo,
    SizeChart,
    currentVariantData,
    isSizeChartEnable,
    variantProducts,
    selectedProduct,
    setCurrentVariantData,
    headerCls,
    attributeCls,
  } = props

  const [outOfStock, setOutOfStock] = useState(false)

  const { isIPadorTablet } = deviceInfo
  const { user } = useUI()

  const trackMoEngageEvent = useMoEngageEvent()

  const sanitizeSize = (value: string) => {
    if (value) {
      return value?.toUpperCase().replaceAll('T', '').replaceAll('V', '')
    }
    return value
  }

  const getVariantProductStock = (
    variantProducts: Array<any>,
    product: any,
    size: any
  ) => {
    const variant = variantProductFilter(
      variantProducts,
      'clothing.size',
      size.fieldValue
    )[0]
    if (variant?.currentStock) {
      return variant?.currentStock
    }
    return undefined
  }

  const getOutofStockHandler = (
    variantProducts: any,
    selectedProduct: any,
    size: any
  ) => {
    if (
      getVariantProductStock(variantProducts, selectedProduct, size) === 0 ||
      getVariantProductStock(variantProducts, selectedProduct, size) ===
        undefined
    ) {
      setOutOfStock(true)
      return true
    } else {
      setOutOfStock(false)
      return false
    }
  }

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-99"
        onClose={(val) => {
          if (!loaderState) {
            handleCloseSizeModal(val)
            setSelectedProduct(undefined)
            if (!val) {
              setProductSizes(undefined)
              setVariantProducts(undefined)
            }
          } else {
            return null
          }
        }}
      >
        <div className="fixed inset-0 left-0 bg-orange-600/[0.15]" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div
              className={`fixed ${
                isMobileOnly ? 'top-full' : 'top-[35%] bottom-[65%]'
              } inset-x-0 right-0 flex max-w-full pl-0 pointer-events-none sm:pl-0`}
            >
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-200 sm:duration-200"
                enterFrom="translate-y-full"
                enterTo="translate-y-0"
                leave="transform transition ease-in-out duration-200 sm:duration-200"
                leaveFrom="translate-y-0"
                leaveTo="translate-y-full"
              >
                <Dialog.Panel className="w-screen pointer-events-auto">
                  <div className="relative flex flex-col h-full shadow-xl bg-black/[.5] z-99">
                    <div className="w-full h-auto max-w-md mx-auto bg-white center-fix-panel">
                      <div
                        className={
                          headerCls
                            ? headerCls
                            : 'flex justify-between w-full px-8 py-3 bg-white border-b border-gray-100 shadow'
                        }
                      >
                        <h4 className="inline font-semibold text-black text-md">
                          {buttonConfig?.buttonTitle}
                        </h4>
                        <button
                          type="button"
                          className="inline p-0 text-black hover:text-black"
                          disabled={loaderState}
                          onClick={() => {
                            handleCloseSizeModal(false)
                            setSelectedProduct(undefined)
                            setProductSizes(undefined)
                            setVariantProducts(undefined)
                          }}
                        >
                          <span className="sr-only">{CLOSE_PANEL}</span>
                          <Cross className="w-6 h-6" aria-hidden="true" />
                        </button>
                      </div>
                      <div
                        className={`flex flex-wrap items-center flex-1 ${
                          !isMobileOnly ? 'gap-4 p-8' : ''
                        } product-sizes`}
                        role="none"
                      >
                        {!productSizes ? (
                          <LoadingDots />
                        ) : (
                          <>
                            <div
                              className={`w-full ${
                                attributeCls ? attributeCls : ''
                              }`}
                            >
                              {SizeChart?.attribute?.length > 0 ? (
                                <>
                                  {SizeChart?.attribute?.map((att: any) => {
                                    return (
                                      <>
                                        {att?.Values?.map(
                                          (data: any, did: any) => {
                                            return (
                                              <>
                                                {matchStrings(
                                                  data?.FieldText ?? '',
                                                  currentVariantData &&
                                                    currentVariantData,
                                                  true
                                                ) && (
                                                  <div
                                                    className="py-1 text-xs font-medium text-black mob-text-xs"
                                                    key={did}
                                                  >
                                                    <span className="mr-1 font-medium text-primary">
                                                      {data?.FieldValue}
                                                    </span>
                                                  </div>
                                                )}
                                              </>
                                            )
                                          }
                                        )}
                                      </>
                                    )
                                  })}
                                </>
                              ) : (
                                <span className="">
                                  {isSizeChartEnable === API_STATUS.LOADING && (
                                    <Skeleton
                                      height="1rem"
                                      width="70%"
                                      style={{
                                        width: '70%',
                                        // margin: 'auto',
                                        marginTop: '5px',
                                        borderRadius: '5px',
                                        background: 'rgba(0, 0, 0, 0.11)',
                                      }}
                                    />
                                  )}
                                </span>
                              )}
                            </div>
                            {isMobileOnly ? (
                              <Swiper
                                slidesPerView={'auto'}
                                spaceBetween={12}
                                loop={false}
                                style={{ padding: '20px 0 30px 0' }}
                              >
                                {productSizes?.map((size: any, idx: number) => {
                                  const isOutOfStock =
                                    variantProducts &&
                                    (getVariantProductStock(
                                      variantProducts,
                                      selectedProduct,
                                      size
                                    ) === 0 ||
                                      getVariantProductStock(
                                        variantProducts,
                                        selectedProduct,
                                        size
                                      ) === undefined)
                                  let sizeCls =
                                    'border-gray-100 bg-gray-100 text-gray-300'
                                  if (
                                    variantProducts &&
                                    getVariantProductStock(
                                      variantProducts,
                                      selectedProduct,
                                      size
                                    ) > 0
                                  ) {
                                    sizeCls = 'bg-white border text-primary'
                                  }

                                  const isSelectedSize = matchStrings(
                                    currentVariantData
                                      ?.toLowerCase()
                                      ?.replaceAll(' ', '-'),
                                    sanitizeSize(
                                      size?.fieldValue
                                        ?.toLowerCase()
                                        ?.replaceAll(' ', '-')
                                    ),
                                    true
                                  )

                                  return (
                                    <SwiperSlide
                                      key={idx}
                                      style={{
                                        width: 'auto',
                                        marginLeft: idx === 0 ? '20px' : '',
                                      }}
                                    >
                                      {selectedProduct && (
                                        <SizeRadioSection
                                          idx={idx}
                                          sizeCls={sizeCls}
                                          isSelectedSize={isSelectedSize}
                                          isOutOfStock={isOutOfStock}
                                          size={size}
                                          sanitizeSize={sanitizeSize}
                                          setCurrentVariantData={
                                            setCurrentVariantData
                                          }
                                          getOutofStockHandler={
                                            getOutofStockHandler
                                          }
                                          variantProducts={variantProducts}
                                          selectedProduct={selectedProduct}
                                          user={user}
                                          trackMoEngageEvent={
                                            trackMoEngageEvent
                                          }
                                          getVariantProductStock={
                                            getVariantProductStock
                                          }
                                        />
                                      )}
                                    </SwiperSlide>
                                  )
                                })}
                              </Swiper>
                            ) : (
                              <>
                                {productSizes?.map((size: any, idx: number) => {
                                  const isOutOfStock =
                                    variantProducts &&
                                    (getVariantProductStock(
                                      variantProducts,
                                      selectedProduct,
                                      size
                                    ) === 0 ||
                                      getVariantProductStock(
                                        variantProducts,
                                        selectedProduct,
                                        size
                                      ) === undefined)
                                  let sizeCls =
                                    'border-gray-100 bg-gray-100 text-gray-300'
                                  if (
                                    variantProducts &&
                                    getVariantProductStock(
                                      variantProducts,
                                      selectedProduct,
                                      size
                                    ) > 0
                                  ) {
                                    sizeCls = 'bg-white border text-primary'
                                  }

                                  const isSelectedSize = matchStrings(
                                    currentVariantData
                                      ?.toLowerCase()
                                      ?.replaceAll(' ', '-'),
                                    sanitizeSize(
                                      size?.fieldValue
                                        ?.toLowerCase()
                                        ?.replaceAll(' ', '-')
                                    ),
                                    true
                                  )

                                  return (
                                    <div key={idx}>
                                      {selectedProduct && (
                                        <SizeRadioSection
                                          idx={idx}
                                          sizeCls={sizeCls}
                                          isSelectedSize={isSelectedSize}
                                          isOutOfStock={isOutOfStock}
                                          size={size}
                                          sanitizeSize={sanitizeSize}
                                          setCurrentVariantData={
                                            setCurrentVariantData
                                          }
                                          getOutofStockHandler={
                                            getOutofStockHandler
                                          }
                                          variantProducts={variantProducts}
                                          selectedProduct={selectedProduct}
                                          user={user}
                                          trackMoEngageEvent={
                                            trackMoEngageEvent
                                          }
                                          getVariantProductStock={
                                            getVariantProductStock
                                          }
                                        />
                                      )}
                                    </div>
                                  )
                                })}
                              </>
                            )}
                          </>
                        )}
                      </div>
                      <div className="flex flex-col p-4 cart-url-button">
                        <Button
                          title={buttonConfig.title}
                          validateAction={buttonConfig.validateAction}
                          action={buttonConfig.action}
                          buttonType={buttonConfig.type || 'cart'}
                          className="!py-0"
                          disabled={outOfStock}
                        />
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default SizeChangePopup
