import { JusPay } from '@components/utils/payment-constants'
import { matchStrings } from '@framework/utils/parse-util'
import { recordGA4Event } from '@components/services/analytics/ga4'
import { isDesktop } from 'react-device-detect'
import {
  getBroswerName,
  getCategories,
  getCurrentPage,
  getLineItemSize,
} from '@framework/utils/app-util'
import { GA_EVENT } from 'hooks/ga_event.modal'
import { EmptyGuid } from '@components/utils/constants'

export const getOfferTexts = (offer: any, cartItems: any) => {
  let offers = new Array<string>()

  if (offer?.order_breakup?.benefits?.length) {
    offer?.order_breakup?.benefits?.forEach((benefit: any) => {
      let text = 'get upto'
      if (
        matchStrings(
          benefit?.calculation_rule,
          JusPay.Offers.CalculationRuleType.PERCENTAGE,
          true
        )
      ) {
        text = `${text} ${benefit?.value}%`
        text = `${text} off. Max discount ${cartItems?.currencySymbol}${benefit?.max_amount}. T&C Apply`
      } else {
        text = `${text} ${cartItems?.currencySymbol}${benefit?.value}`
        text = `${text} off. Max discount ${cartItems?.currencySymbol}${benefit?.value}. T&C Apply`
      }
      offers.push(text)
    })
  }
  return offers
}

export const handleLoginAttemptGAEvent = (user: any, previousPath: string) => {
  recordGA4Event(window, GA_EVENT.LOGIN_ATTEMPT, {
    device: isDesktop ? 'Desktop' : 'Mobile',
    browser: getBroswerName(),
    current_page: getCurrentPage(),
    previous_page: previousPath,
    loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
  })
}

export const selectPromotionGAEvent = async (
  items: any,
  promoName: string,
  promoCode: string,
  user: any
) => {
  recordGA4Event(window, GA_EVENT.SELECT_PROMOTION, {
    ecommerce: {
      items: [
        items?.lineItems?.map((item: any, itemId: number) => ({
          item_name: item?.name,
          item_brand: item?.brand,
          item_category2: getCategories(item).category2,
          item_category: getCategories(item).category,
          item_variant: item?.colorName,
          quantity: item?.qty,
          item_id: item?.sku,
          item_size: getLineItemSize(
            item?.stockCode
              ?.toUpperCase()
              ?.substring(item?.stockCode?.lastIndexOf('-') + 1),
            item?.variantProductsAttribute
          ),
          price: item?.price?.raw?.withTax,
          item_var_id: item?.stockCode,
          item_list_name: getCategories(item).category,
          index: itemId,
        })),
      ],
      promotion_name: promoName,
      promotion_promocode: promoCode,
      selected_coupon: 'single',
      current_page: getCurrentPage(),
      loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
    },
  })
}

export const handleProductClickGAEvent = (user: any, couponData: any) => {
  recordGA4Event(window, GA_EVENT.VIEW_PRODUCTS_CLICK, {
    loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
    current_page: getCurrentPage(),
    coupon_name: couponData?.couponName,
    destination_url: couponData?.destinationUrl,
  })
}
