// Base Imports
import { Fragment, useEffect } from 'react'
// Package Imports
import { Dialog, Transition } from '@headlessui/react'
import { openLoginView, getCurrentPage } from '@framework/utils/app-util'
import { useUI } from '@components/ui'
import { EmptyGuid } from '@components/utils/constants'
import { useGoogleAnalyticsEvent } from 'hooks/useGAEvent'
import { useRouter } from 'next/router'
import { GA_EVENT } from 'hooks/ga_event.modal'
// import { Cross } from '@components/icons'
declare const window: any

const LoginLogoutAskModal = (props: any) => {
  const trackGoogleAnalyticsEvent = useGoogleAnalyticsEvent()
  const { show, close, deviceInfo, isOnlyMobile, modalTitle, customClasses } =
    props
  const { asPath } = useRouter()
  const { setSidebarView, deleteUser, user } = useUI()

  useEffect(() => {
    trackGoogleAnalyticsEvent(
      GA_EVENT.RELOGIN_POP_UP_APPEARS,
      {
        current_page: getCurrentPage(),
        loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
      },
      window
    )
    return () => {
      trackGoogleAnalyticsEvent(
        GA_EVENT.RELOGIN_POP_UP_DISAPPEARS,
        {
          current_page: getCurrentPage(),
          loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
        },
        window
      )
    }
  }, [])

  if (typeof window === 'undefined') {
    return null
  }

  return (
    <>
      <Transition.Root show={show} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-99"
          onClose={() => {
            // close()
          }}
        >
          <div className="fixed inset-0 left-0 bg-orange-900/20" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div
                className={`fixed ${
                  isOnlyMobile ? 'top-auto bottom-0' : 'inset-y-0'
                } right-0 flex max-w-full pl-0 pointer-events-none bottom-to-top sm:pl-0`}
              >
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-200 sm:duration-200"
                  enterFrom="translate-y-full"
                  enterTo="translate-y-0"
                  leave="transform transition ease-in-out duration-200 sm:duration-200"
                  leaveFrom="translate-y-0"
                  leaveTo="translate-y-full"
                >
                  <Dialog.Panel className="w-screen max-w-md pointer-events-auto">
                    <div className="relative z-50 flex flex-col h-full bg-white shadow-xl">
                      <div className="z-10 px-0 py-0 pb-0 sm:px-0 left-1 top-1">
                        <div className="flex justify-between px-6 py-6 pb-4 mb-0 border-b sm:px-6">
                          <h3
                            className="text-xl font-bold text-black truncate dark:text-black"
                            style={{ maxWidth: '80vw' }}
                          >
                            {modalTitle}
                          </h3>
                          {/* <button
                            type="button"
                            className="text-black rounded-md outline-none hover:text-gray-500"
                            onClick={() => {
                              close()
                            }}
                          >
                            <span className="sr-only">Close panel</span>
                            <Cross
                              className="relative top-0 w-7 h-7"
                              aria-hidden="true"
                            />
                          </button> */}
                        </div>
                      </div>
                      <div className="p-6 py-2 overflow-y-auto">
                        <p className="py-2 text-xs text-black opacity-50">
                          Please login to access this
                        </p>
                        <div className="grid grid-cols-2 flex justify-between items-center pt-3 pb-5 space-x-2">
                          <div className="px-0 py-4 pt-0 mt-2 chk-btn sm:px-0">
                            <button
                              className="w-full flex items-center justify-center btn-basic-property -mr-0.5 text-white bg-black border-2 border-black rounded-sm hover:bg-gray-800 hover:text-white sm:px-6 hover:border-gray-900"
                              onClick={() => {
                                trackGoogleAnalyticsEvent(
                                  GA_EVENT.RELOGIN_POP_LOGIN_CLICKED,
                                  {
                                    current_page: getCurrentPage(),
                                    prompt_name: 'Relogin',
                                    loggedin_status: !!(
                                      user?.userId && user?.userId !== EmptyGuid
                                    ),
                                  },
                                  window
                                )
                                openLoginView(asPath)
                                setSidebarView('LOGIN_VIEW')
                                close()
                              }}
                            >
                              <>Login</>
                            </button>
                          </div>
                          <div className="px-0 py-4 pt-0 mt-2 chk-btn sm:px-0">
                            <button
                              className="w-full flex items-center justify-center btn-basic-property -mr-0.5 text-white bg-black border-2 border-black rounded-sm hover:bg-gray-800 hover:text-white sm:px-6 hover:border-gray-900"
                              onClick={() => {
                                trackGoogleAnalyticsEvent(
                                  GA_EVENT.RELOGIN_POP_LOGOUT_CLICKED,
                                  {
                                    current_page: getCurrentPage(),
                                    prompt_name: 'Relogin',
                                    loggedin_status: !!(
                                      user?.userId && user?.userId !== EmptyGuid
                                    ),
                                  },
                                  window
                                )
                                deleteUser()
                                close()
                              }}
                            >
                              <>Logout</>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}
export default LoginLogoutAskModal
