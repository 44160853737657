import axios from '@services/axiosInterceptorInstance'
import { createMachineId } from './generateMachineId'

export const getData = (url: string) => axios.get(url).then((res) => res.data)

export const postData = (url: string, body: any) => {
  return axios
    .post(url, body, {
      headers: {
        machineId: createMachineId(),
      },
    })
    .then((res) => {
      return res.data
    })
}
