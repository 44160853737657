import { Image } from '@components/common'
import { IBasketPromos } from '@components/common/utils/models/cart/convenience.model'

interface IProps {
  basketPromos: IBasketPromos
}

const MoreOfferMsg = (props: IProps) => {
  const { basketPromos } = props

  return (
    <div className="flex flex-col my-2">
      {basketPromos?.availablePromotions?.length > 0 && (
        <>
          {basketPromos?.availablePromotions
            ?.filter((x: any) => !!x?.croMessage)
            ?.map((promo: any, crdx: number) => {
              return (
                <>
                  <div
                    className="relative pl-16 my-1 m-offer-info bg-sky-offer offer-m-sec text-secondary-full-opacity"
                    key={crdx}
                  >
                    <span className="absolute leading-none top-img-15 -translate-y-2/4 left-2">
                      <Image
                        priority={true}
                        blurDataURL="https://dacdn2.damensch.com/damensch/icons/more-offer-icon.svg"
                        placeholder="blur"
                        className="w-auto"
                        src="https://dacdn2.damensch.com/damensch/icons/more-offer-icon.svg"
                        alt=""
                        width={15}
                        height={10}
                      />
                    </span>{' '}
                    {promo.croMessage}
                  </div>
                </>
              )
            })}
        </>
      )}

      {basketPromos?.applicablePromotions?.length > 0 && (
        <>
          {basketPromos?.applicablePromotions
            ?.filter((x: any) => x?.promoType == 21 && !!x?.croMessage)
            ?.map((promo: any, crdx: number) => {
              return (
                <>
                  <div
                    className="relative pl-16 my-1 m-offer-info bg-sky-offer offer-m-sec text-secondary-full-opacity"
                    key={crdx}
                  >
                    <span className="absolute leading-none top-img-15 -translate-y-2/4 left-2">
                      <Image
                        priority={true}
                        blurDataURL="https://dacdn2.damensch.com/damensch/icons/more-offer-icon.svg"
                        placeholder="blur"
                        className="w-auto"
                        src="https://dacdn2.damensch.com/damensch/icons/more-offer-icon.svg"
                        alt=""
                        width={15}
                        height={10}
                      />
                    </span>{' '}
                    {promo.croMessage}
                  </div>
                </>
              )
            })}
        </>
      )}
    </div>
  )
}

export default MoreOfferMsg
