import { FC } from 'react'
import Image, { ImageProps } from 'next/image'

const CustomImage: FC<ImageProps> = (props) => {
  const { alt, width, height, sizes, style, ...rest } = props
  return (
    <>
      <Image
        data-id="custom-image"
        alt={alt}
        placeholder="blur"
        width={width ?? 0}
        height={height ?? 0}
        sizes={sizes ?? "100vw"}
        style={style ?? {}}
        {...rest}
      />
    </>
  )
}

export default CustomImage
