const WhiteCartIcon = ({ ...props }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 1.875C8.28369 1.875 6.875 3.28369 6.875 5V5.625H3.78906L3.75 6.21094L3.125 17.4609L3.08594 18.125H16.9141L16.875 17.4609L16.25 6.21094L16.2109 5.625H13.125V5C13.125 3.28369 11.7163 1.875 10 1.875ZM10 3.125C11.0352 3.125 11.875 3.96484 11.875 5V5.625H8.125V5C8.125 3.96484 8.96484 3.125 10 3.125ZM4.96094 6.875H6.875H8.125H11.875H13.125H15.0391L15.5859 16.875H4.41406L4.96094 6.875Z"
        fill="white"
      />
    </svg>
  )
}

export default WhiteCartIcon
