import { isMobile, isMobileOnly } from 'react-device-detect'
import { recordGA4Event } from '@components/services/analytics/ga4'
import { EmptyGuid } from '@components/utils/constants'
import { GA_EVENT } from 'hooks/ga_event.modal'
import { MO_ENGAGE_EVENT } from 'hooks/moengage_event.modal'

interface ISizeRadioSection {
  idx: any
  sizeCls: string
  isSelectedSize: any
  isOutOfStock: any
  size: any
  sanitizeSize: (value: any) => void
  setCurrentVariantData: any
  getOutofStockHandler: (
    variantProducts: any,
    selectedProduct: any,
    size: any
  ) => void
  variantProducts: any
  selectedProduct: any
  user: any
  trackMoEngageEvent: any
  getVariantProductStock: (
    variantProducts: any,
    selectedProduct: any,
    size: any
  ) => number
}

const SizeRadioSection = (props: ISizeRadioSection) => {
  const {
    idx,
    sizeCls,
    isSelectedSize,
    isOutOfStock,
    size,
    sanitizeSize,
    setCurrentVariantData,
    getOutofStockHandler,
    variantProducts,
    selectedProduct,
    user,
    trackMoEngageEvent,
    getVariantProductStock,
  } = props

  return (
    <div
      key={idx}
      className="relative flex justify-center text-center"
      id={`headlessui-radiogroup-option-${idx}`}
      role="radio"
      aria-checked="false"
    >
      <div
        className={`size-option ${
          isSelectedSize ? 'font-medium' : 'font-light'
        } ${
          isSelectedSize ? 'text-primary border-2 border-gray-800' : ''
        } -m-0.5 relative p-2 w-16 h-16 border-opacity-1 rounded-sm text-md content-center text-center flex items-center justify-center cursor-pointer focus:outline-none hover:bg-zinc-50 ${sizeCls}`}
        style={{
          background:
            isOutOfStock && isSelectedSize ? 'rgba(224, 0, 0, 0.08)' : '',
          border: isOutOfStock && isSelectedSize ? '2px solid #E00000' : '',
        }}
        data-size={sanitizeSize(size?.fieldValue)}
        onClick={async (ev: any) => {
          const target: any = ev?.currentTarget
          if (target) {
            setCurrentVariantData(size?.fieldValue)
            getOutofStockHandler(variantProducts, selectedProduct, size)
            document
              .querySelector(
                'div.product-sizes div.size-option.border-gray-800'
              )
              ?.classList?.remove('border-gray-800')
            target?.classList?.add('border-gray-800')
            if (typeof window !== 'undefined') {
              recordGA4Event(window, GA_EVENT.SIZE_CHANGE, {
                category2: selectedProduct?.categoryItems?.length
                  ? selectedProduct?.categoryItems[0]?.categoryName
                  : '',
                category: selectedProduct?.categoryItems?.length
                  ? selectedProduct?.categoryItems[
                      selectedProduct?.categoryItems?.length - 1
                    ]?.categoryName
                  : '',
                size_clicked: size?.fieldValue,
                product_name: selectedProduct?.name,
                device: isMobileOnly ? 'Mobile' : 'Desktop',
                current_page: 'Cart',
                loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
              })
            }
            trackMoEngageEvent(
              MO_ENGAGE_EVENT.SIZES,
              {
                URL: window?.location?.href,
                label: size?.fieldValue,
                action: 'Click',
                size_clicked: size?.fieldValue,
                Product_name: selectedProduct?.name,
              },
              window
            )
          }
        }}
      >
        <p className="sr-only" id="headlessui-label-66" />
        <a aria-hidden="true" className="uppercase" href="javascript: void(0);">
          {' '}
          {size?.fieldValue}{' '}
        </a>
      </div>
      {variantProducts &&
        (getVariantProductStock(variantProducts, selectedProduct, size) >= 0 ||
          getVariantProductStock(variantProducts, selectedProduct, size) ===
            undefined) &&
        (isOutOfStock ? (
          <div className="absolute block text-center w-28 -bottom-6">
            <span
              className={`font-semibold text-red-600 uppercase ${
                isMobile ? 'text-[10px]' : 'text-xxs'
              }`}
            >
              Sold Out
            </span>
          </div>
        ) : (
          getVariantProductStock(variantProducts, selectedProduct, size) <
            5 && (
            <div className="absolute text-center -bottom-6">
              <span
                className={`block w-20 font-semibold text-red-600 uppercase ${
                  isMobile ? 'text-[10px]' : 'text-xxs'
                }`}
              >
                {getVariantProductStock(variantProducts, selectedProduct, size)}{' '}
                Left
              </span>
            </div>
          )
        ))}
    </div>
  )
}

export default SizeRadioSection
