import { useState, useEffect } from 'react'
import axios from '@services/axiosInterceptorInstance'
import {
  BAG_FREE_GIFT,
  CART_TEXT,
  CURRENCY_SYMBOL_RUPEE,
  DAYS_15,
  DAYS_30,
  DAYS_500,
  GENERAL_FREE_GIFT_MESSAGE,
  GENERAL_REWARD_MESSAGE,
} from '@components/utils/textVariables'
import {
  HIDE_OLD_COUPONS_LAYOUT,
  DISABLE_SHOW_COUPONS_BELOW,
  DISABLE_SHOW_COUPONS_TOP,
  HIDE_EXTRA_PRODUCT_OFFER_CARD,
  FREQUENTLY_BOUGHT_TOGETHER_BTM_BTN,
} from '@framework/utils/constants'
import {
  NEXT_GET_PRODUCT,
  PRODUCTS_SLUG_PREFIX,
} from '@components/utils/constants'
import { ProductService } from 'services/product/index.service'
import { round } from '@framework/utils/lodash'
import { matchStrings, tryParseJson } from '@framework/utils/parse-util'
import CartFreeGift from './FreeGift'
import RelatedProducts from '@components/product/RelatedProducts'
import PromotionInput from '../PromotionInput'
import CartItemCard from './CartItemCard'
import { getlineItemSizeWithoutSlug } from '@framework/utils/app-util'
import { IDeviceInfo, useUI } from '@components/ui/context'
import OfferItemCard from './../../MobileCart/OfferItemCard'

interface ICartItems {
  reValidateData: any
  chipestProductId: number
  deviceInfo: IDeviceInfo
  handleViewItem: () => void
  handleQuickAddToBag: (product: any) => void
  handleItem: (
    product: any,
    name: string,
    bool?: boolean,
    qty?: number,
    page?: string
  ) => void
  showRemove: (products: Array<any> | any) => void
  chipestProduct: any
  basketPromos: any
  paymentOffers: any
  getBasketPromos: (basketId: string) => void
  relatedProductData: any
  wishlistLoader: boolean
  currentPage: string
  previousPath: string
  setLoader: any
  convenienceData?: any
  handleSoftAddProduct?: (props: any) => void
}

const CartItemsSection = (props: ICartItems) => {
  const {
    reValidateData,
    chipestProductId,
    deviceInfo,
    handleViewItem,
    handleQuickAddToBag,
    handleItem,
    showRemove,
    chipestProduct,
    basketPromos,
    paymentOffers,
    getBasketPromos,
    relatedProductData,
    wishlistLoader,
    currentPage,
    previousPath,
    setLoader,
    convenienceData,
    handleSoftAddProduct,
  } = props
  const [offerItemCards, setOfferItemCards] = useState(null)
  const { cartItems } = useUI()

  let Addon = cartItems?.lineItems?.find((x: any) =>
    matchStrings(x?.brand, 'Damensch', true)
  )

  const freeItem =
    cartItems?.lineItems?.findIndex(
      (item: any) => item?.price?.raw?.withTax == 0
    ) != -1
      ? true
      : false

  const userCart = {
    ...cartItems,
    lineItems: cartItems?.lineItems?.sort((a: any, b: any) => {
      if (a?.price?.raw?.withTax === 0) {
        return -1
      } else if (b?.price?.raw?.withTax === 0) {
        return 1
      } else {
        return 0
      }
    }),
  }

  const fetchProduct = async (productDataSlug: string) => {
    if (productDataSlug) {
      const productSlug = productDataSlug?.replace(PRODUCTS_SLUG_PREFIX, '')
      const response: any = await axios.post(NEXT_GET_PRODUCT, {
        slug: productSlug?.startsWith('/')
          ? productSlug?.substring(1)
          : productSlug,
      })
      return response
    }
  }
  const filterProductsByCategory = (softAddedProduct: any, lineItems: any) => {
    const lineItemsCategories = lineItems?.reduce(
      (categories: any, item: any) => {
        categories.add(item.categoryItems?.[1]?.categoryName)
        return categories
      },
      new Set()
    )
    const filteredProducts = softAddedProduct?.map((product: any) => {
      let counter = 0
      if (lineItemsCategories?.has(product?.softaddedproduct_category)) {
        counter++
      }
      return { ...product, counter }
    })
    return filteredProducts
  }
  const getList = async () => {
    const newSoftAddedProductList = filterProductsByCategory(
      convenienceData?.softAddedProduct,
      cartItems?.lineItems
    )
    let isAllowed = true
    for (let idx = 0; idx < newSoftAddedProductList?.length; idx++) {
      const val = newSoftAddedProductList[idx]
      for (let idx = 0; idx < newSoftAddedProductList.length; idx++) {
        if (val?.counter === 0 && isAllowed) {
          try {
            const data = await fetchProduct(val?.softaddedproduct_slug)
            if (data?.data?.product?.currentStock) {
              isAllowed = false
              setOfferItemCards({ ...val, productDetails: data?.data?.product })
            }
          } catch (error) {
            console.error('Error calling API for idx', idx)
          }
        } else {
          const itemDetails = offerItemCards
            ? Object.assign({}, offerItemCards)
            : { softaddedproduct_stockcode: null }
          if (
            itemDetails &&
            itemDetails?.softaddedproduct_stockcode ===
              val?.softaddedproduct_stockcode &&
            val?.counter > 0
          ) {
            setOfferItemCards(null)
          }
        }
      }
    }
  }
  const getOfferItemCard = () => {
    return (
      offerItemCards && (
        <OfferItemCard
          details={offerItemCards}
          handleSoftAddProduct={handleSoftAddProduct}
        />
      )
    )
  }
  useEffect(() => {
    if (!HIDE_EXTRA_PRODUCT_OFFER_CARD) getList()
  }, [cartItems?.lineItems?.length, convenienceData?.softAddedProduct?.length])

  const getCouponSection = () => {
    return (
      <PromotionInput
        basketPromos={basketPromos}
        paymentOffers={paymentOffers}
        items={cartItems}
        getBasketPromoses={getBasketPromos}
        deviceInfo={deviceInfo}
        previousPath={previousPath}
        hideBlueStrips={!!HIDE_OLD_COUPONS_LAYOUT}
        showPromoList={!!HIDE_OLD_COUPONS_LAYOUT}
      />
    )
  }

  return (
    <>
      {!DISABLE_SHOW_COUPONS_TOP ? (
        <div className="flex flex-col">
          <div className="section-devider-sm-btm" />
          <div className="rounded-lg sm:mt-16 lg:py-0 lg:mt-0 lg:col-span-5">
            {getCouponSection()}
          </div>
          <div className="section-devider-sm margin-y-0" />
        </div>
      ) : (
        <div className="flex flex-col">
          <div className="section-devider-sm margin-y-0" />
        </div>
      )}
      <div className="flex-1 px-4 pt-0 sm:px-0">
        {cartItems.subTotal?.raw?.withTax >= 2000 && Addon?.itemType == 10 && (
          <div className="grid content-center w-full px-0 sm:px-8">
            <div className="flex content-center justify-between gap-0 py-4 mb-4 border-b border-gray-200">
              <div className="flex mt-2">
                <span className="sprite-icon gift-icon" />
              </div>
              <div className="flex flex-col">
                <h3 className="font-semibold text-md">
                  {GENERAL_FREE_GIFT_MESSAGE}
                </h3>
                <p className="text-xs font-normal text-gray-500">
                  {GENERAL_REWARD_MESSAGE} {CURRENCY_SYMBOL_RUPEE}2000!
                </p>
              </div>
              <div className="flex mt-2">
                <h3 className="text-sm font-semibold text-orange-500">
                  {BAG_FREE_GIFT}
                </h3>
              </div>
            </div>
          </div>
        )}
        <div className="px-0 mt-4 sm:px-8">
          <div className="flow-root">
            <ul role="list" className="-my-6 divide-y divide-gray-200">
              {chipestProduct && (
                <div className="no-change-button">
                  <CartFreeGift
                    data={chipestProduct}
                    size={getlineItemSizeWithoutSlug(chipestProduct)}
                    deviceInfo={deviceInfo}
                    basketPromos={basketPromos}
                  />
                </div>
              )}
              {userCart?.lineItems?.map((product: any, idx: number) => {
                let lineItemPromoValue = 0
                if (product?.promotionsApplied?.length) {
                  product?.promotionsApplied?.map((promoApplied: any) => {
                    lineItemPromoValue =
                      lineItemPromoValue +
                      promoApplied?.discountAmt?.raw?.withTax
                  })
                }

                const saving =
                  product?.listPrice?.raw?.withTax * product?.qty -
                  product?.price?.raw?.withTax * product?.qty
                let discount = round(
                  (saving / (product?.listPrice?.raw?.withTax * product?.qty)) *
                    100,
                  0
                )

                if (lineItemPromoValue) {
                  const addedDiscount = round(
                    (lineItemPromoValue /
                      (product?.listPrice?.raw?.withTax * product.qty)) *
                      100,
                    0
                  )
                  discount = discount + addedDiscount
                }
                let soldOutMessage = ''
                if (reValidateData?.message != null) {
                  soldOutMessage = reValidateData?.message?.includes(
                    product.stockCode
                  )
                }

                let replacementMessage = ''
                const replacement: any = tryParseJson(product?.customInfo4)
                if (replacement) {
                  replacementMessage =
                    replacement?.formatted?.data['Replacement Eligibility']
                  if (replacementMessage) {
                    const is500DayReplaceable = replacementMessage
                      ?.toString()
                      ?.trim()
                      ?.toLowerCase()
                      ?.replace(/[^a-zA-Z0-9]/g, '')
                      ?.includes(DAYS_500)
                    const is30DayReplaceable = replacementMessage
                      ?.toString()
                      ?.trim()
                      ?.toLowerCase()
                      ?.replace(/[^a-zA-Z0-9]/g, '')
                      ?.includes(DAYS_30)
                    if (is30DayReplaceable) {
                      replacementMessage = `<span class='text-brown-light'>${replacementMessage} Day 1st Try Guarantee</span>`
                    } else {
                      replacementMessage = `<span class='text-brown-light'>${replacementMessage} Day Replacement</span>`
                    }
                  }
                }

                let returnDataMessage = ''
                const returnData: any = tryParseJson(product?.customInfo5)
                if (returnData) {
                  returnDataMessage =
                    returnData?.formatted?.data['Return Eligibility']
                  if (returnDataMessage) {
                    const isReturnable = returnDataMessage
                      ?.toString()
                      ?.trim()
                      ?.toLowerCase()
                      ?.replace(/[^a-zA-Z0-9]/g, '')
                      ?.includes(DAYS_15)
                    returnDataMessage = `<span class='text-brown-light'>${returnDataMessage} Day Return/Exchange</span>`
                  }
                }

                return (
                  product?.id != chipestProductId && (
                    <>
                      {product?.price?.raw?.withTax == 0 ? (
                        <>
                          <CartFreeGift
                            data={product}
                            key={idx}
                            size={getlineItemSizeWithoutSlug(product)}
                            deviceInfo={deviceInfo}
                            basketPromos={basketPromos}
                          />
                        </>
                      ) : (
                        <>
                          {product?.children?.map((child: any, idx: number) => {
                            return (
                              <CartFreeGift
                                data={child}
                                key={idx}
                                size={getlineItemSizeWithoutSlug(child)}
                                deviceInfo={deviceInfo}
                                basketPromos={basketPromos}
                              />
                            )
                          })}
                          <li key={product.id} className="">
                            <CartItemCard
                              handleViewItem={handleViewItem}
                              product={product}
                              lineItemPromoValue={lineItemPromoValue}
                              discount={discount}
                              replacementMessage={replacementMessage}
                              returnDataMessage={returnDataMessage}
                              freeItem={freeItem}
                              handleQuickAddToBag={handleQuickAddToBag}
                              handleItem={handleItem}
                              reValidateData={reValidateData}
                              soldOutMessage={soldOutMessage}
                              cartItems={cartItems}
                              showRemove={showRemove}
                              currentPage={currentPage}
                              previousPath={previousPath}
                            />
                          </li>
                        </>
                      )}
                    </>
                  )
                )
              })}
            </ul>
          </div>
        </div>
        {!HIDE_EXTRA_PRODUCT_OFFER_CARD && (
          <div className="mt-6">{getOfferItemCard()}</div>
        )}
      </div>
      {!DISABLE_SHOW_COUPONS_BELOW && (
        <div className="flex flex-col">
          <div className="section-devider-sm margin-top-0" />
        </div>
      )}
      {!DISABLE_SHOW_COUPONS_BELOW && (
        <div className="flex flex-col mt-0">{getCouponSection()}</div>
      )}
      <>
        <div className="flex flex-col">
          <div className="section-devider-sm margin-top-0" />
        </div>
        <div className="flex flex-col mt-0 cart-related-prod">
          {relatedProductData?.length && (
            <RelatedProducts
              relatedProducts={relatedProductData}
              productPerColumn={1.8}
              checkout_refrence={true}
              title="Frequently Bought Together"
              handleQuickAddToBag={handleQuickAddToBag}
              deviceInfo={deviceInfo}
              pageType={CART_TEXT}
              wishlistLoader={wishlistLoader}
              getBasketPromos={getBasketPromos}
              setLoader={setLoader}
              showBtmBtn={FREQUENTLY_BOUGHT_TOGETHER_BTM_BTN}
            />
          )}
        </div>
      </>
      <div className="flex flex-col">
        <div className="section-devider-sm" />
      </div>
    </>
  )
}

export default CartItemsSection
