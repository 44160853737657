import React, { useEffect, useRef } from 'react'
import { AppsFlyerKeys, AppsFlyerParams, EmptyGuid } from './constants'
import { useUI } from '@components/ui'
import {
  getCommonAppsFlyerValue,
  getCurrentPage,
} from '@framework/utils/app-util'
import { GA_EVENT } from 'hooks/ga_event.modal'
import { recordGA4Event } from '@components/services/analytics/ga4'
import { useRouter } from 'next/router'
import { PromotionInfo } from '@components/common/utils/models/promotion/promotionInfo.model'

declare const window: any
declare const AF: any
export interface IBannerData {
  position: number
  banner_name: string
  component_type: string
  component_name: string
  url?: string
  device?: string
  mid_banner_position?: number
}
export interface IItem {
  item_name: string
  item_id: number
  price: string
  item_brand: string
  item_category: string
  item_category1?: string
  item_category2: string
  item_variant: string
  item_list_name: string
  item_list_id?: number
  index: number
  item_var_id: number
}

interface IProps {
  title: string
  eventName: GA_EVENT
  items?: IItem[]
  bannerData?: IBannerData
  _threshold?: number
  nudgeData?: PromotionInfo
}
const IntersectionObserverComponent: React.FC<IProps> = ({
  children,
  title,
  eventName,
  items,
  bannerData,
  _threshold,
  nudgeData,
}) => {
  const targetRef = useRef<HTMLDivElement>(null)
  const { user } = useUI()
  const currentPage = getCurrentPage()
  const router = useRouter()

  useEffect(() => {
    const options: IntersectionObserverInit = {
      root: null, // Use the viewport as the root
      rootMargin: '0px',
      threshold: _threshold ? _threshold : 0.5, // Trigger when at least 50% of the element is visible
    }

    const callback: IntersectionObserverCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // The target element is within the viewport
          if (eventName === GA_EVENT.VIEW_ITEM_LIST && items?.length) {
            if (typeof window !== 'undefined') {
              recordGA4Event(window, GA_EVENT.VIEW_ITEM_LIST, {
                ecommerce: {
                  item_list_name: title,
                  items,
                  loggedin_status: !!(
                    user?.userId && user?.userId !== EmptyGuid
                  ),
                  sectionTitle: title,
                  currentPage,
                },
              })
            }
            if (typeof AF !== 'undefined') {
              const commonEventValue = getCommonAppsFlyerValue()
              AF('pba', 'event', {
                eventType: 'EVENT',
                eventName: AppsFlyerKeys.ViewItems,
                eventRevenueCurrency: 'INR',
                eventValue: {
                  [AppsFlyerParams.AFEventParam3]: currentPage,
                  [AppsFlyerParams.AFEventParamContent]: title,
                  ...commonEventValue,
                },
              })
            }
          } else if (eventName === GA_EVENT.BANNER_IMPRESSIONS && bannerData) {
            if (window?.location?.href?.includes('#cartopen')) {
              return
            }
            recordGA4Event(window, GA_EVENT.BANNER_IMPRESSIONS, {
              current_page: currentPage,
              position: bannerData.position,
              banner_name: bannerData.banner_name,
              component_type: bannerData.component_type,
              component_name: bannerData.component_name,
              loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
              ...(bannerData.device ? { device: bannerData.device } : {}),
              ...(bannerData.mid_banner_position
                ? { mid_banner_position: bannerData.mid_banner_position }
                : {}),
            })
          } else if (
            eventName === GA_EVENT.BOTTOM_NUDGE_IMPRESSION &&
            nudgeData
          ) {
            recordGA4Event(window, GA_EVENT.BOTTOM_NUDGE_IMPRESSION, {
              promotion_name: nudgeData?.name,
              promotion_promocode: nudgeData?.code,
              current_page: getCurrentPage(),
              loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
            })
          }
        }
      })
    }

    const observer = new IntersectionObserver(callback, options)
    if (targetRef.current) {
      observer.observe(targetRef.current)
    }

    // Clean up the observer when the component is unmounted
    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current)
      }
    }
  }, [])

  return <div ref={targetRef}>{children}</div>
}

export default IntersectionObserverComponent
