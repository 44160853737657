import axios from 'axios'
import { NEW_RELIC_LOG_API, NEW_RELIC_LOG_API_KEY } from './constants'
import { BASE_URL } from '@framework/utils/constants'

export enum LOGGER_TYPE {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  INFO = 'INFO',
}

export enum ENV_FROM_BASE_URL {
  DEV = 'DEV',
  BETA = 'BETA_NEW',
  PROD = 'PROD_NEW',
}
export interface ILogger {
  apiPath: string
  message: string
  error?: string
  type: LOGGER_TYPE
  pageType: string
  fetcherStartTime?: number
  fetcherEndTime?: number
  fetcherResponseTime?: number
  operationsEndTime?: number
  operationsStartTime?: number
  operationsResponseTime?: number
  apiCatalogStartTime?: number
  apiCatalogEndTime?: number
  apiCatalogResponseTime?: number
  failedOn?: string
  machineId: string
  functionType?: string
}

export const storeLogs = async (log: ILogger) => {
  const isLocal = process?.env?.IS_LOCAL && process.env.IS_LOCAL === 'true'
  const getEnv = (baseUrl: string) => {
    if (baseUrl?.includes('dev-damensch.com')) {
      return ENV_FROM_BASE_URL.DEV
    } else if (baseUrl?.includes('beta-damensch.com')) {
      return ENV_FROM_BASE_URL.BETA
    } else if (baseUrl?.includes('daapi.damensch.com')) {
      return ENV_FROM_BASE_URL.PROD
    } else {
      return undefined
    }
  }
  if (!!NEW_RELIC_LOG_API) {
    await axios.post(`${NEW_RELIC_LOG_API}?Api-Key=${NEW_RELIC_LOG_API_KEY}`, {
      ...log,
      logtype: log.type,
      env: getEnv(BASE_URL || ''),
    })
  }
}
