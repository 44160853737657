import { useUI } from '@components/ui'

interface IProps {
  singleSelectPromo: Array<any>
  isAppLoading: boolean
  applyCoupon: (promo: any, ev: any) => void
}

const SingleSelectPromos = (props: IProps) => {
  const { singleSelectPromo, isAppLoading, applyCoupon } = props

  const { cartItems } = useUI()

  const applied = cartItems?.promotionsApplied?.find((x: any) => x.promoCode)

  return (
    <>
      {singleSelectPromo?.length > 0 && (
        <>
          <div className="flex flex-col">
            <div className="grid grid-cols-1">
              <div>
                <h4 className="text-lg font-semibold text-dark-brown">
                  Applicable Offers
                </h4>
              </div>
              {singleSelectPromo?.map((promo: any, idx: number) => {
                return (
                  promo?.promoType != 22 &&
                  (promo?.useWithOtherDiscountCode == 1 ||
                    promo?.useWithOtherDiscountCode == 2 ||
                    promo?.useWithOtherDiscountCode == 4) && (
                    <>
                      <div key={idx} className="mt-3 border border-gray-200">
                        <div className="grid grid-cols-12">
                          <div
                            className={`relative col-span-1 text-white coupon-cross ${
                              applied?.promoCode == promo?.code
                                ? `bg-orange-500 coupon-code-panel`
                                : 'bg-orange-500 coupon-code-panel'
                            }`}
                          >
                            <h3 className="font-semibold text-white uppercase coupon-text-rotate">
                              {promo?.additionalInfo7}
                            </h3>
                          </div>
                          <div className="col-span-11 p-4 coupon-code-data">
                            <div className="flex justify-between">
                              <h3 className="mb-1 text-sm font-semibold text-black uppercase">
                                {promo?.code}
                              </h3>
                              {applied?.promoCode == promo?.code ? (
                                <>
                                  <button
                                    type="button"
                                    className="text-sm font-semibold text-emerald-500"
                                  >
                                    Applied
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button
                                    type="button"
                                    disabled={isAppLoading}
                                    className="text-sm font-semibold text-orange-500"
                                    onClick={(ev: any) =>
                                      !isAppLoading && applyCoupon(promo, ev)
                                    }
                                  >
                                    Apply
                                  </button>
                                </>
                              )}
                            </div>
                            <div className="flex flex-col">
                              <>
                                {promo?.croSuccessMessage != '' ? (
                                  <p className="text-xs font-medium text-emerald-500">
                                    {promo?.croSuccessMessage}
                                  </p>
                                ) : (
                                  <>
                                    <p className="text-xs font-medium text-emerald-500">
                                      {promo?.name}
                                    </p>
                                  </>
                                )}
                              </>
                            </div>
                            <div className="flex flex-col pt-4 mt-4 border-t border-gray-200 border-dotted px">
                              <p className="text-xs font-normal text-gray-400">
                                {promo?.additionalInfo6}
                              </p>
                            </div>
                            {promo?.promoType == 9 ? (
                              <div className="flex flex-col pt-4 mt-4 border-t border-gray-200 border-dotted px">
                                <p className="text-xs font-normal text-gray-400">
                                  Applying this coupon will make all items in
                                  the cart EXCHANGE ONLY
                                </p>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )
                )
              })}
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default SingleSelectPromos
