import { GreenCart, RedCart } from '@components/icons'
import { useUI } from '@components/ui'
import {
  MIN_SHOPPING_COST,
  MORE_TO_AVOID,
  NO_SHIPPING_FEE,
  ON_THIS_ORDER,
  SHIPPING_FEE,
  SHOP_FOR,
  YAY,
} from '@components/utils/textVariables'
import { priceFormat } from '@framework/utils/parse-util'

interface ICartShippingHeader {
  isChannelStore?: boolean
}

const CartShippingHeader = (props: ICartShippingHeader) => {
  const { isChannelStore } = props
  const { cartItems } = useUI()

  const itemsInBag = () => {
    return cartItems?.lineItems
      ?.map((item: any) => item.qty)
      .reduce((sum: number, current: number) => sum + current, 0)
  }

  let costForFreeShipping =
    MIN_SHOPPING_COST -
    parseFloat(
      (
        cartItems.subTotal?.raw?.withTax - cartItems.discount?.raw?.withTax
      ).toFixed(2)
    )

  return (
    <>
      {itemsInBag() > 0 && !isChannelStore && (
        <span className="flex justify-start items-center">
          {cartItems.shippingCharge?.raw?.withTax > 0 && itemsInBag() > 0 ? (
            <>
              {costForFreeShipping > 0 && (
                <div className="flex justify-start items-center w-full px-4 py-2 border-b sm:px-8">
                  <RedCart className="block w-6 h-6" aria-hidden="true" />
                  <h3 className="ml-3 text-12 text-[#C10000]">
                    {SHOP_FOR}{' '}
                    <span className="font-semibold">
                      {priceFormat(costForFreeShipping)}{' '}
                    </span>
                    {MORE_TO_AVOID}{' '}
                    <span className="font-semibold">
                      {priceFormat(cartItems.shippingCharge?.raw?.withTax)}{' '}
                      {SHIPPING_FEE}
                    </span>
                  </h3>
                </div>
              )}
            </>
          ) : (
            <div className="flex justify-start items-center w-full px-4 py-2 border-b sm:px-8">
              <GreenCart className="block w-6 h-6" aria-hidden="true" />
              <h3 className="ml-3 text-12 text-green">
                {YAY} <span className="font-semibold">{NO_SHIPPING_FEE} </span>
                {ON_THIS_ORDER}
              </h3>
            </div>
          )}
        </span>
      )}
    </>
  )
}

export default CartShippingHeader
