const NewCouponIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      {...props}
    >
      <path
        d="M18.2143 3.42957L17.4632 4.18781L14.5956 6.97952L10.6696 6.42807L9.61134 6.25574L9.44064 7.32417L8.75787 11.2532L5.24159 13.1488L4.28571 13.6658L4.76365 14.5964L6.50472 18.2153L4.76365 21.8342L4.28571 22.7647L5.24159 23.2817L8.75787 25.1773L9.44064 29.1064L9.61134 30.1748L10.6696 30.0025L14.5956 29.451L17.4632 32.2428L18.2143 33.001L18.9653 32.2428L21.833 29.451L25.7589 30.0025L26.8172 30.1748L26.9879 29.1064L27.6707 25.1773L31.187 23.2817L32.1428 22.7647L31.6649 21.8342L29.9238 18.2153L31.6649 14.5964L32.1428 13.6658L31.187 13.1488L27.6707 11.2532L26.9879 7.32417L26.8172 6.25574L25.7589 6.42807L21.833 6.97952L18.9653 4.18781L18.2143 3.42957ZM20.3992 11.5979L13.8445 24.8327H16.0294L22.584 11.5979H20.3992ZM14.3907 13.8037C13.4861 13.8037 12.7521 14.5447 12.7521 15.458C12.7521 16.3714 13.4861 17.1124 14.3907 17.1124C15.2954 17.1124 16.0294 16.3714 16.0294 15.458C16.0294 14.5447 15.2954 13.8037 14.3907 13.8037ZM22.0378 19.3182C21.1331 19.3182 20.3992 20.0592 20.3992 20.9725C20.3992 21.8859 21.1331 22.6269 22.0378 22.6269C22.9425 22.6269 23.6765 21.8859 23.6765 20.9725C23.6765 20.0592 22.9425 19.3182 22.0378 19.3182Z"
        fill="#EC5E28"
        {...props}
      />
      <path
        d="M12.7521 15.458C12.7521 14.5447 13.4861 13.8037 14.3907 13.8037C15.2954 13.8037 16.0294 14.5447 16.0294 15.458C16.0294 16.3714 15.2954 17.1124 14.3907 17.1124C13.4861 17.1124 12.7521 16.3714 12.7521 15.458Z"
        fill="white"
      />
      <path
        d="M13.8445 24.8327L20.3992 11.5979H22.584L16.0294 24.8327H13.8445Z"
        fill="white"
      />
      <path
        d="M20.3992 20.9725C20.3992 20.0592 21.1331 19.3182 22.0378 19.3182C22.9425 19.3182 23.6765 20.0592 23.6765 20.9725C23.6765 21.8859 22.9425 22.6269 22.0378 22.6269C21.1331 22.6269 20.3992 21.8859 20.3992 20.9725Z"
        fill="white"
      />
    </svg>
  )
}

export default NewCouponIcon
